'use client'

import { ArrowLeft24 } from '@vinted/monochrome-icons'
import { Button, ColorCode, InputText, Spacer, Text } from '@vinted/web-ui'
import { isEmpty } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'

import { useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import {
  renderValidation,
  UiState,
  useFormValidationMessage,
} from '@marketplace-web/shared/ui-helpers'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { viewScreenEvent } from '_libs/common/event-tracker/events'
import { EMAIL_CODE_VERIFICATION_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import useRefUrl from 'hooks/useRefUrl'

import { EmailCodeView } from '../constants'
import Context from '../EmailVerificationCodeContext'
import useEmailForm from '../hooks/useEmailForm'
import ConfirmationModal from './ConfirmationModal'
import { Submit } from './types'

const NotReceiveEmail = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { email: userEmail, uiState, canUserChangeEmail } = useContext(Context)
  const { handleSubmit, register, errors, getValues } = useEmailForm()
  const { pushHistoryState } = useLocation()
  const refUrl = useRefUrl()

  const isSendingCode = uiState === UiState.Pending
  const isCodeSent = uiState === UiState.Success

  const getErrorMessage = useFormValidationMessage(
    errors,
    'email_verification_code.not_receive_email.inputs',
  )

  const navigateToEnterCodeView = useCallback(() => {
    pushHistoryState(
      urlWithParams(EMAIL_CODE_VERIFICATION_URL, {
        view: EmailCodeView.EnterCode,
        ref_url: refUrl,
      }),
    )
  }, [pushHistoryState, refUrl])

  useEffect(() => {
    track(viewScreenEvent({ screen: Screen.MandatoryEmailVerificationResendCode }))
  }, [track])

  useEffect(() => {
    if (!isCodeSent) return

    navigateToEnterCodeView()
  }, [isCodeSent, navigateToEnterCodeView])

  const handleEmailSubmit = ({ useOldEmail }: Submit = {}) => {
    setIsOpenModal(false)

    handleSubmit({ useOldEmail })
  }

  const handleBackClick = () => {
    navigateToEnterCodeView()
  }

  const handleResendClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isSendingCode) {
      event.preventDefault()

      return
    }

    const { email } = getValues()

    if (email === userEmail) {
      handleSubmit()

      return
    }

    setIsOpenModal(true)
  }

  const renderTitle = () => {
    return (
      <Text as="h1" type={Text.Type.Heading}>
        {translate('email_verification_code.not_receive_email.title')}
      </Text>
    )
  }

  const renderDescription = () => {
    return (
      <Text as="span" html theme="muted">
        {translate('email_verification_code.not_receive_email.description')}
      </Text>
    )
  }

  const renderContactSupportText = () => {
    if (canUserChangeEmail) return null

    return (
      <>
        <Text as="span" html theme="muted" testId="contact_support">
          {translate('email_verification_code.not_receive_email.contact_support')}
        </Text>
        <Spacer size={Spacer.Size.X2Large} />
      </>
    )
  }

  const renderInput = () => {
    return (
      <InputText
        {...register}
        disabled={!canUserChangeEmail}
        title={translate('email_verification_code.not_receive_email.inputs.email.title')}
        type={InputText.Type.Email}
        inputMode={InputText.InputMode.Email}
        styling={InputText.Styling.Tight}
        validation={
          renderValidation(getErrorMessage('email')) ||
          translate('email_verification_code.not_receive_email.inputs.email.info')
        }
      />
    )
  }

  const handleModalClose = () => {
    setIsOpenModal(false)
  }

  const renderButtons = () => {
    return (
      <>
        <Button
          testId="not-receive-email--resend-button"
          onClick={handleResendClick}
          styling={Button.Styling.Filled}
          disabled={!isEmpty(errors)}
          isLoading={isSendingCode}
        >
          {translate('email_verification_code.not_receive_email.actions.resend')}
        </Button>
        <Spacer size={Spacer.Size.Medium} />
        <Button
          onClick={handleBackClick}
          iconName={ArrowLeft24}
          iconColor={ColorCode.Primary}
          disabled={isSendingCode}
          testId="back-button"
        >
          {translate('email_verification_code.not_receive_email.actions.back')}
        </Button>
      </>
    )
  }

  const renderConfirmationModal = () => {
    const { email } = getValues()

    if (!isOpenModal) return null

    return (
      <ConfirmationModal email={email} onConfirm={handleEmailSubmit} onClose={handleModalClose} />
    )
  }

  return (
    <>
      {renderTitle()}
      <Spacer size={Spacer.Size.Regular} />
      {renderDescription()}
      <Spacer size={Spacer.Size.X2Large} />
      {renderInput()}
      <Spacer size={Spacer.Size.X2Large} />
      {renderContactSupportText()}
      <Spacer size={Spacer.Size.Regular} />
      {renderButtons()}
      {renderConfirmationModal()}
    </>
  )
}

export default NotReceiveEmail
