import { VerificationPromptDto } from 'types/dtos'
import { VerificationPromptModel } from 'types/models/verification-prompt'

export const transformVerificationPromptDto = (
  verificationDto: VerificationPromptDto,
): VerificationPromptModel => ({
  id: verificationDto.id,
  mandatory: verificationDto.mandatory,
  methods: verificationDto.methods,
  category: verificationDto.category,
  actions: verificationDto.actions,
  imageUrls: verificationDto.image_urls,
  translations: {
    header: verificationDto.translations.header,
    body: verificationDto.translations.body,
  },
  trigger: verificationDto.trigger,
})
