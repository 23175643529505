'use client'

import { Loader } from '@vinted/web-ui'
import { useEffect } from 'react'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { useFetch } from '@marketplace-web/shared/api-client'
import { navigateToPage, useLocation } from '@marketplace-web/shared/browser'
import { useSession } from '@marketplace-web/shared/session'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { createUser2FA } from 'data/api'
import { transformTwoFactorVerification } from 'state/checkout/transformers'

import TwoFactorVerification from '../../components/TwoFactorVerification'
import { VerificationType } from '../../constants'

const BankTwoFactorVerification = () => {
  const {
    redirect_url: redirectUrl,
    ref_url: refUrl,
  }: { redirect_url?: string; ref_url?: string } = useLocation().searchParams

  const userId = useSession().user?.id

  const handleCreateUser2FA = async ({ id }: { id: number }) => {
    return createUser2FA({ userId: id, fingerprint: await getFingerprint() })
  }

  const { fetch, transformedData, isLoading } = useFetch(
    handleCreateUser2FA,
    transformTwoFactorVerification,
  )

  const is2FANotRequired = transformedData?.askUser2fa === false

  useEffect(() => {
    if (!userId) return

    fetch({ id: userId })
  }, [fetch, userId])

  useEffect(() => {
    if (!is2FANotRequired) return

    navigateToPage(urlWithParams(refUrl || '/', { ref_url: redirectUrl }))
  }, [is2FANotRequired, redirectUrl, refUrl, transformedData])

  if (isLoading || !transformedData || is2FANotRequired) {
    return (
      <div className="u-flexbox u-justify-content-center">
        <Loader size={Loader.Size.XLarge} testId="bank-login-two-factor-verification-loader" />
      </div>
    )
  }

  return (
    <div className="narrow-container">
      <TwoFactorVerification
        redirectUrl={redirectUrl}
        refUrl={refUrl}
        verificationType={VerificationType.BankAccount}
        maskedInfo={transformedData.userMaskedPhoneNumber}
        nextResendAvailableIn={transformedData.nextResendAvailableIn}
        showResend={transformedData.showResendOption}
        twoFAId={transformedData.id}
      />
    </div>
  )
}

export default BankTwoFactorVerification
